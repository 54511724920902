/* Gradients: https://cssgradient.io/ */
/* Color Palette Generator: https://palx.jxnblk.com/ */
/* Color Palette Generator: http://colormind.io/bootstrap/ */

@import './color-variables.css';
@import './text.css';
@import './backgrounds.css';
@import './Materialize-modifications.css';
/* TODO: CHANGE TO "MATERIALIZE MODIFICATIONS" */
@import './Materialize-datepicker.css';
@import './pagination.css';

/*=======================
       SECURITY
=========================*/
@media print {
	html,
	body {
		display: none; /* hide whole page */
	}
}

/* ----- REGION TEMPLATE ----- */
/* #region */
/* #endregion */

/* ----- GENERAL | TO ORGANIZE ----- */
/* #region */

.pill {
	background-color: var(--gray-1);
	border-radius: 16px;
	color: var(--gray1);
	display: inline-block;
	font-size: 11px;
	padding: 0.3rem 12px;
}

.headline {
	text-transform: uppercase;
	font-weight: 500;
	font-size: 3rem;
	margin-top: 1rem;
}

td {
	padding: 5px !important;
}

.btn-large i.fab {
	font-size: 2.25rem;
}

sub,
sup {
	color: #9e9e9e;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	/* font-family: 'Merriweather', serif; */
	font-family: 'Asap', sans-serif;
}
body {
	font-family: 'Raleway', sans-serif !important;
}

ul,
ol {
	margin-bottom: 1rem !important;
}
.card ol li {
	list-style-type: decimal !important;
	/* margin-left: 1.75rem !important; */
}
.card ul li {
	list-style-type: square !important;
	/* margin-left: 1.75rem !important; */
}

ul.collapsible li {
	list-style-type: none !important;
}

ul li.collection-item {
	list-style-type: none !important;
	margin-left: 0 !important;
}

ul.no-list-style li {
	list-style-type: none !important;
}

table#test-records thead,
table#user-records thead,
table.post-test-review thead {
	font-weight: 700;
	background: var(--brand-1);
	color: var(--brand-white);
}

thead td {
	border-radius: 0 !important;
	padding: 15px 5px;
}

tbody > tr:nth-child(even) {
	background: var(--brand-white) !important;
}
button {
	border: none;
	background: transparent;
	color: var(--brand);
	cursor: pointer;
	margin: 0 1rem;
}

button.no-focus:focus {
	background-color: #fff !important;
}

button.btn:focus {
	background-color: var(--brand-3) !important;
}
button.btn-flat:hover,
button.btn-flat:focus {
	background-color: var(--brand) !important;
	color: var(--white);
}

button.bg-secondary:hover,
button.bg-secondary:focus {
	background-color: var(--gray-1) !important;
	color: var(--gray2) !important;
}
button.btn-icon:hover,
button.btn-icon:focus {
	background-color: transparent;
}

form.flex-center {
	width: 95%;
	margin-left: 5%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-flow: column wrap;
}

.explanation ul,
.fuente ul {
	display: block;
	list-style-type: disc;
	margin-block-start: 1em;
	margin-block-end: 1em;
	margin-inline-start: 0px;
	margin-inline-end: 0px;
	margin: 0 0 1rem 0 !important;
	padding-inline-start: 40px;
	padding-left: 40px !important;
}

.explanation ul li {
	list-style-type: square !important;
	display: list-item;
	text-align: -webkit-match-parent;
}

/* .card-panel.teal > .explanation p > span {
    color: white !important;
} */

article {
	margin-bottom: 1rem;
}
details {
	text-align: right;
}
summary {
	padding: 1vh;
}

summary:focus {
	outline: solid 1px transparent;
	cursor: pointer;
}

.ql-editor p > br {
	display: inline !important;
}

.ql-editor p {
	display: block;
	margin: 1em 0 !important;
}

p > br {
	display: none;
}

p.ql-align-center {
	text-align: center;
	width: 90%;
	margin: 0 auto;
}

.simulacion-link,
.pruebin-link,
.tutor-link {
	align-self: center;
	border-radius: 2px;
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
		0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
	height: 12vh;
	font-size: 1.5rem;
	line-height: 1.5rem;
	margin: 1vmax 0;
	padding: 5vh 2vmax;
	text-align: center;
	width: 100%;
}

.simulacion-link:hover,
.pruebin-link:hover,
.tutor-link:hover {
	filter: brightness(1.1);
}

.btn:hover,
.btn-large:hover,
.btn-small:hover {
	background-color: var(--brand-accent) !important;
}

.btn.bg-red:hover,
.btn.bg-red:focus,
.btn.bg-danger:hover,
.btn.bg-danger:focus {
	background-color: var(--danger) !important;
}

.normal-bullets > li {
	list-style-type: circle !important;
	margin-left: 2.5vmax;
}

.normal-links {
	text-decoration: underline;
}
/* #endregion */

/*=======================
       NAVBAR
=========================*/
/*#region*/
nav .logo {
	height: 85%;
	max-width: 15%;
	/* vertical-align: middle; */
}

nav .brand-logo {
	position: relative !important;
	display: inline !important;
}

nav ul li.bg-brand1 a:hover {
	background-color: var(--brand) !important;
}
nav ul li a:hover {
	background-color: var(--brand1) !important;
	color: var(--main-bg) !important;
}

nav ul a:hover {
	background-color: var(--light-gray);
}

nav ul li a#log-off:hover {
	background-color: var(--danger) !important;
}
/*#endregion*/

/*=======================
       LAYOUT
=========================*/
/*#region*/
body {
	margin: 0;
	padding: 0;
	font-family: sans-serif;
	color: var(--black-darker);
}

form {
	margin: 2vh 0;
}

div.form-padding {
	padding: 5vh 3vw;
}

select {
	background-color: transparent;
	color: #000000;
	width: 100%;
	padding: 5px;
	border: 1px solid #f2f2f2;
	border-radius: 2px;
	height: 3rem;
	border: 0;
	border-bottom: 1px solid #9e9e9e;
}

select#category:active,
option {
	color: rgba(0, 0, 0, 0.87);
}

select:focus {
	border-bottom: 1px solid var(--brand-2);
	-webkit-box-shadow: 0 1px 0 0 var(--brand-2);
	box-shadow: 0 1px 0 0 var(--brand-2);
}

.card .card-content p {
	margin-block-start: 1em;
	margin-block-end: 1em;
	margin-inline-start: 0px;
	margin-inline-end: 0px;
}

.card .card-content p {
	margin-bottom: 1.15rem !important;
}

p.hide-overflow {
	max-height: 12vh;
	overflow: hidden;
}

p.hide-overflow::after {
	content: '... See More';
}

/*changes select options background*/
/* #category:enabled * {
	background: #6ab4ad;
	color: white;
} */

select option:disabled {
	color: #9e9e9e;
}

.main section {
	min-height: 75vh;
	padding: 2vmax;
}

.main .btn-large {
	font-size: 1.15rem;
}

.br-2,
.card {
	border-radius: 1vmin !important;
}

.overflow-hidden {
	overflow: hidden;
}

.link {
	background-color: transparent;
	border: none;
	box-shadow: none;
	color: var(--brand-accent);
	cursor: pointer;
	outline: 0;
}
.link:hover {
	color: var(--brand1);
}
.link:focus {
	background-color: transparent;
}

footer {
	background: var(--black);
	font-size: 0.88rem;
	color: var(--brand-white);
	padding: 3.5vmax 0;
	text-align: left;
}

footer ul li {
	padding: 0.2rem 0 0.2rem 0.25rem;
}
footer ul li a {
	color: var(--darker-shade);
}
footer ul li a:hover {
	color: var(--alternate2-off-white);
	text-decoration: underline;
}

.copyright {
	background: var(--black-darker);
	color: var(--main-bg);
	font-size: 0.85rem;
	padding: 0.1vmax 0;
}

/*#endregion*/

/*=======================
       UTILITY
=========================*/
/* #region */

/* --- SECTIONS --- */
/*#region*/
main.dashboard {
	padding: 2vmax 0 0 0;
}
section.section-1 {
	padding: 5vmax 0;
}
section.section-2 {
	padding: 5vmax 0;
	background-color: var(--brand-white);
}
/* #endregion */

/* --- FONT SIZE --- */
/*#region*/
small {
	font-size: 0.85rem !important;
}
.fsize-display {
	font-size: 2.5rem !important;
	font-weight: 500;
}
.fsize-small {
	font-size: small !important;
}
.fsize-medium {
	font-size: medium !important;
}
.fsize-larger {
	font-size: larger !important;
}
.fsize-1 {
	font-size: 0.95rem !important;
}
.fsize-2 {
	font-size: 1.2rem !important;
}
.fsize-3 {
	font-size: 1.6rem !important;
}
.fsize-4 {
	font-size: 2.5rem !important;
}

@media only screen and (max-width: 790px) {
	.fsize-display {
		font-size: 1.5rem !important;
		font-weight: 500;
	}
}
/* #endregion */

/* --- DISPLAY --- */
/*#region*/
.d-block {
	display: block;
}
.d-none {
	display: none;
}

.d-inline {
	display: inline;
}
.d-flex {
	display: flex;
}
.d-grid {
	display: grid;
}
.d-flex-center {
	display: flex !important;
	justify-content: center;
	align-items: center;
}
.align-center {
	justify-content: center;
	align-items: center;
}
/*#endregion*/
/* ----- MARGINS ----- */
/* #region */
.m-auto {
	margin: 0 auto !important;
}
.m-0 {
	margin: 0 !important;
}
.mt-0 {
	margin-top: 0 !important;
}
.mt-1 {
	margin-top: 1rem !important;
}
.mt-2 {
	margin-top: 2rem !important;
}
.mt-4 {
	margin-top: 4rem !important;
}
.mt-5 {
	margin-top: 5rem !important;
}
.mt-6 {
	margin-top: 6rem !important;
}
.mb-0 {
	margin-bottom: 0 !important;
}

.mb-2 {
	margin-bottom: 0.75rem !important;
}
.mb-3 {
	margin-bottom: 2.5rem !important;
}
.mb-4 {
	margin-bottom: 4rem !important;
}

.ml-2 {
	margin-left: 1.5rem !important;
}
.ml-3 {
	margin-left: 2.5rem !important;
}
.ml-4 {
	margin-left: 3.5rem !important;
}

.my-1 {
	margin-top: 0.25rem !important;
	margin-bottom: 0.25rem !important;
}
.my-2 {
	margin: 1rem 0 !important;
}
.my-3 {
	margin: 2rem 0 !important;
}
.my-4 {
	margin: 3.5rem 0 !important;
}

.mt-3 {
	margin-top: 1rem !important;
}

.mr-1 {
	margin-right: 1rem !important;
}
.mr-2 {
	margin-right: 1.5rem !important;
}
.mr-3 {
	margin-right: 2rem !important;
}
.mr-4 {
	margin-right: 2.85rem !important;
}

.mx-1 {
	margin-left: 1rem !important;
	margin-right: 1rem !important;
}
.mx-2 {
	margin-left: 2rem !important;
	margin-right: 2rem !important;
}
.mx-4 {
	margin-left: 4rem !important;
	margin-right: 4rem !important;
}
.mx-auto {
	margin: 0 auto;
}
/* #endregion */

/* ----- PADDINGS ----- */
/* #region */
.p-0 {
	padding: 0 !important;
}
.p-1 {
	padding: 1vmax !important;
}
.p-2 {
	padding: 2vmax !important;
}
.pw-2 {
	padding: 0 2vmax !important;
}
.pw-4 {
	padding: 0 4vmax !important;
}
.px-0 {
	padding-left: 0 !important;
	padding-right: 0 !important;
}
.px-1 {
	padding-left: 0.5rem !important;
	padding-right: 0.5rem !important;
}
.py-1 {
	padding-top: 0.7vmax !important;
	padding-bottom: 0.7vmax !important;
}
.py-2 {
	padding-top: 2vmax !important;
	padding-bottom: 2vmax !important;
}
.py-4 {
	padding-top: 4rem !important;
	padding-bottom: 4rem !important;
}
.pr-0 {
	padding-right: 0 !important;
}
.pr-1 {
	padding-right: 1rem !important;
}
.pr-2 {
	padding-right: 2rem !important;
}
.pb-1 {
	padding-bottom: 1rem !important;
}
.pb-2 {
	padding-bottom: 1.5rem !important;
}
/* PADDING TOP */
.pt-0 {
	padding-top: 0 !important;
}
.pt-1 {
	padding-top: 1vmax !important;
}
.pt-2 {
	padding-top: 2vmax !important;
}

.pt-3 {
	padding-top: 3vmax !important;
}
.pt-4 {
	padding-top: 4vh !important;
}
.pt-5 {
	padding-top: 5vh !important;
}
/* PADDING LEFT */
.pl-1 {
	padding-left: 1vmax !important;
}
.pl-2 {
	padding-left: 2vmax !important;
}
/* #endregion */

/* ----- WIDTH ----- */
/* #region */
.w-25 {
	width: 25%;
}
.w-50 {
	width: 50%;
}
.w-65 {
	width: 65%;
}
.w-75 {
	width: 75%;
}
.w-100 {
	width: 100%;
}

/* #endregion */

/* ----- OTHER ----- */
/* #region */
.c-pointer {
	cursor: pointer;
}
span.c-pointer:hover {
	background: var(--main-bg);
}
td span.c-pointer:hover {
	background: transparent;
	text-decoration: underline;
	color: var(--main-bg);
}

.circle {
	height: 3.85rem;
	width: 3.85rem;
	background-color: transparent;
	border-radius: 50%;
	display: inline-block;
}
.circle2 {
	height: 5rem;
	width: 5rem;
	background-color: transparent;
	border-radius: 50%;
	display: inline-block;
}
/* #endregion */
/* #endregion */
/*=======================
       CUSTOMIZATIONS
=========================*/
/*#region*/

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}
strong {
	font-weight: 600 !important;
}
.card-title strong {
	font-weight: 400 !important;
}

.alert {
	padding: 1.5vh;
	background: hsla(52, 73%, 56%, 0.5);
	/* color: white; */
	outline: 1px solid var(--yellow);
}

.alert h5 {
	font-size: 1.35rem;
	margin-top: 0.5rem;
}

.report-issues {
	color: var(--danger);
	/* position: absolute;
	top: 0;
	right: 1vh; */
}

.card-content-reduced-padding {
	padding: 1.25rem !important;
}

/* .onHover-teal-bg {
	transition: box-shadow 0.25s !important;
} */

.onHover-teal-bg:hover {
	background: rgba(0, 150, 136, 0.05);
	/* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19) !important; */
}
/*#endregion*/

/* MATERIALIZE MODIFICATIONS*/
/*#region*/

/* SELECTS */
h2 {
	font-size: calc(15px + 2vmax);
}

span.badge {
	/* font-size: calc(6px + 0.5vmax); */
	border-radius: 1vmax;
	padding: 0 1vmax !important;
}

.badge-spacing {
	margin-top: 5px;
}

.material-icons {
	vertical-align: middle;
}

/* UTILITY */
/* #region */

.makeAdminSearchForm {
	width: 80%;
	margin: 0 auto;
}

.hide {
	display: none;
}

.flex-center {
	display: flex;
	width: 100%;
	padding: 0;
	margin: 0;
	align-items: center;
	justify-content: center;
	flex-direction: row;
}
#uploader {
	-webkit-appearance: none;
	appearance: none;
	width: 50%;
	height: 20px;
}

progress[value]::-webkit-progress-bar {
	background-color: #eee;
	border-radius: 2px;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
}

progress[value]::-webkit-progress-value {
	background: var(--brand);
	border-radius: 2px;
	background-size: 35px 20px, 100% 100%, 100% 100%;
}

/* Add imge modal: https://www.w3schools.com/css/tryit.asp?filename=trycss_image_modal_js */
.img-responsive {
	max-width: 100%;
	height: auto;
}

.img-thumbnail {
	border: 1px solid #ddd;
	border-radius: 4px;
	padding: 5px;
	width: 150px;
}
/* #endregion */

/*=======================
       SALES PAGE
=========================*/
/*#region*/
.decor-above-fold {
	/* position: absolute; */
	/* left: 1%;
	top: 10%; */
	border-radius: 15px 0 750px 0;
	background: var(--alternate2-off-white);
	padding-top: 3vmax !important;
	width: 70%;
	height: 80vh;
	z-index: 0;
}
.above-the-fold {
	padding-top: 0 !important;
	width: 85%;
}
.heroshot {
	position: absolute;
	top: 20%;
	right: 10%;
}

.gif-right {
	margin: 3rem 0 3rem 5rem;
}
.gif-left {
	margin: 3rem 5rem 3rem 0;
}

/*endregion*/
/* ===== STATS =====*/
/*#region */
.stats {
	position: relative;
	transition: box-shadow 0.25s;
}

.stat-cards {
	display: flex !important;
	justify-content: space-evenly;
	flex-wrap: wrap;
	margin-top: 4rem;
}

.stat-cards .card-panel {
	background: var(--white);
}

.stat-cards .card-panel .circle2 {
	border: 2px solid var(--alternate2-1);
}

.stat-cards .data {
	background: var(--alternate2-off-white);
	background: var(--alternate2-2);
	font-size: 1.45rem;
	padding-top: 0.75rem;
}

.inset-shadow {
	-webkit-box-shadow: inset 2px 0 2px 0 rgba(0, 0, 0, 0.14),
		inset 3px 0 1px -2px rgba(0, 0, 0, 0.12),
		inset 1px 0 5px 0 rgba(0, 0, 0, 0.2);
	-moz-box-shadow: inset 2px 0 2px 0 rgba(0, 0, 0, 0.14),
		inset 3px 0 1px -2px rgba(0, 0, 0, 0.12),
		inset 1px 0 5px 0 rgba(0, 0, 0, 0.2);
	box-shadow: inset 2px 0 2px 0 rgba(0, 0, 0, 0.14),
		inset 3px 0 1px -2px rgba(0, 0, 0, 0.12),
		inset 1px 0 5px 0 rgba(0, 0, 0, 0.2);
}
.dashboard .row .stats {
	min-height: 100vmax;
	padding: 0 4%;
}
/*#endregion*/
/*
 ===== TEST MODE STYLING =====
 */
/* #region */
article span p {
	display: inline;
}
.bg-test-mode-explanation {
	background-color: var(--white) !important;
	color: var(--gray3) !important;
}

.test-selection-summary {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	z-index: 1001;
	background: var(--lighter-shade);
}

.full-bg {
	background: var(--light-gray);
	height: 110vh;
	position: relative;
}

.test-mode {
	position: absolute;
	width: 100%;
	min-height: 100vh;
	height: fit-content;
}

.bg-test-mode {
	min-height: 100%;
	/* Gradiend: */
	background: var(--test-mode-bg2);
	background: -moz-linear-gradient(
		122deg,
		var(--test-mode-bg3) 0%,
		var(--test-mode-bg2) 70%,
		var(--test-mode-bg1) 100%
	);
	background: -webkit-linear-gradient(
		122deg,
		var(--test-mode-bg3) 0%,
		var(--test-mode-bg2) 70%,
		var(--test-mode-bg1) 100%
	);
	background: linear-gradient(
		122deg,
		var(--test-mode-bg3) 0%,
		var(--test-mode-bg2) 70%,
		var(--test-mode-bg1) 100%
	);
	/* filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0f433e",endColorstr="#26a69a",GradientType=1); */
}

.test-mode .container {
	padding-top: 15vh;
}

.test-results {
	background: var(--white);
	border-radius: 5px;
	font-size: 1.1rem;
	font-weight: 500;
	min-height: 15vh;
	padding: 5vh;
}

.test-result-right-answer {
	padding-left: 1.5rem;
	border-left: 6px solid var(--success);
}
.test-result-wrong-answer {
	padding-left: 1.5rem;
	border-left: 6px solid var(--danger);
}

ul.answers > li {
	color: var(--black);
}

.question {
	background: var(--white);
	color: var(--black);
	font-size: 1.1rem;
	font-weight: 500;
	min-height: 15vh;
	padding: 5vh;
	border-radius: 5px;
}

.answers {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-auto-rows: minmax(100px, auto);
}

.answer {
	background: var(--white);
	margin: 0 0.8vh 1.5vh 0.8vh;
	width: 98%;
	border-radius: 5px;
	min-height: 100px;
	display: flex;
}

.answer > button {
	/* background: var(--white); */
	background: inherit;
	border: none;
	border-radius: 2px;
	box-shadow: none !important;
	color: var(--black-darker);
	cursor: pointer;
	/* font-size: 1.1rem; */
	/* height: -webkit-fill-available; */
	/* padding: 4vh 5vh; */
	/* padding: 0 5vh; */
	padding: 2vmin 5vmin;
	width: 100%;
	transition: background-color 0.2s ease-out;
	text-align: left;
}

.answer > button:focus {
	background-color: #fff !important;
}

.selected-answer {
	outline: var(--brand-accent) solid 3px !important;
}
.selected-answer > button {
	font-weight: 600;
}

.outline-main {
	outline: var(--brand) solid 2px;
}

.right-answer {
	color: var(--success) !important;
	background: #edfef0 !important;
	font-weight: 600;
}
.wrong-answer {
	color: var(--danger) !important;
	background: var(--danger-lighter);
}
.bg-right-answer {
	background: var(--success);
	color: var(--white);
	/* outline: 5px var(--success) solid;
	border-radius: 2px; */
}

.bg-wrong-answer {
	background: var(--danger);
	color: var(--white);
	/* outline: 5px var(--danger) solid;
	border-radius: 2px; */
}

.answer > button:disabled {
	cursor: auto;
	color: inherit;
	background: inherit;
}
/* .answer > button:disabled.right-answer {
	color: var(--success);
	background: var(--light-gray);
	font-weight: 600;
}
.answer > button:disabled.wrong-answer {
	color: var(--danger);
	background: var(--light-gray);
} */

.next-question-button {
	height: 5vh;
	margin: 0 5vh;
	text-align: right;
	margin-bottom: 5vmax;
}

.bg-next-question {
	background-color: var(--brand) !important;
}

details {
	cursor: pointer;
}

summary:hover {
	background: var(--main-bg);
	color: var(--gray);
}

/* summary.contact-message {
} */

summary.contact-message:hover {
	background: transparent;
	/* outline: 1px white solid; */
	color: inherit;
}

summary.contact-message > div#details-marker,
summary.contact-message::-webkit-details-marker {
	float: left;
	padding-top: 5px;
}

.bl-white {
	border-left: 5px solid #fff !important;
}

.bl-teal {
	border-left: 5px solid #4db6ac !important;
}

blockquote strong {
	font-weight: 600 !important;
}

.outline-main {
	outline: #4db6ac solid 2px;
}
/* #endregion */

.tag {
	background: #e1e1e1;
	padding: 0.45vmax 0.8vmax;
	margin-right: 0.5vmax;
	cursor: pointer;
	display: inline;
	font-size: calc(9px + 0.5vmin);
}

.tag:hover {
	background: #c8c8c8;
}

.tag.selected {
	background: #bbbbbb !important;
}

.material-small-font {
	font-size: 15px;
}
.modal-report {
	height: 50% !important;
}
.modal-textarea {
	width: 75%;
	margin: 0 auto;
}

.timer-separator {
	font-size: large;
	padding: 0 2vh;
}

.react-stars:focus {
	outline: none;
}

table.historic-grade-summary thead tr td {
	width: 20%;
}

table.historic-grade-data thead tr td {
	text-align: center;
	font-weight: bold;
	width: 33.33%;
}
table.historic-grade-data tbody tr td {
	text-align: center;
}

table.post-test-review thead tr td {
	width: 10%;
}
table.post-test-review thead tr td:nth-child(7) {
	width: 30%;
}
table.post-test-review thead tr td:nth-child(8),
table.post-test-review tbody tr td:nth-child(8) {
	text-align: center;
}

/* table.historic-grade-summary thead tr td:nth-child(1) {
	width: 20%;
} */

.border-0 {
	border: 0;
	outline: 0;
}

/* ----- QUILL ----- */
/*#region*/
.ql-editor ol > li,
.ql-editor ul > li {
	list-style-type: none !important;
	margin-left: 0 !important;
}

.app .ql-container {
	border-bottom-left-radius: 0.5em;
	border-bottom-right-radius: 0.5em;
	background: #fefcfc;
}

.ql-container {
	min-height: 100px !important;
}

/* Snow Theme */
.app .ql-snow.ql-toolbar {
	display: block;
	background: #eaecec;
	border-top-left-radius: 0.5em;
	border-top-right-radius: 0.5em;
}

/* Bubble Theme */
.app .ql-bubble .ql-editor {
	border: 1px solid #ccc;
	border-radius: 0.5em;
}

.app .ql-editor {
	min-height: 18em;
}

.themeSwitcher {
	margin-top: 0.5em;
	font-size: small;
}
/*#endregion*/

/* ----- MOBILE FRIENDLY ADJUSTMENTS | mobile friendly----- */
/*#region*/
@media only screen and (max-width: 790px) {
	.headline {
		font-size: 2rem;
	}
	.w-m-100 {
		width: 100%;
	}
	.center-on-small-only {
		text-align: center !important;
	}
	div h3 {
		padding-top: 2rem;
	}
	html {
		font-size: 14px !important;
	}
	h2 {
		font-size: 2.88rem !important;
	}
	h3 {
		font-size: 2.2rem !important;
	}
	p.fsize-3 {
		font-size: 1.3rem !important;
	}
	table {
		font-size: 9px !important;
	}

	table#test-records thead tr td,
	table#user-records thead tr td {
		text-align: center;
	}

	/* thead > tr > td:nth-child(4),
	tbody > tr > td:nth-child(4), */
	/* table#test-records thead tr td,
	table#user-records thead tr td,
	table#test-records tbody tr td,
	table#user-records tbody tr td {
		display: none !important;
	} */

	.gif-right {
		margin: 0;
	}
	.gif-left {
		margin: 0;
	}
	.btn-large {
		font-size: 1.2rem !important;
		padding: 0 22px !important;
	}
	.pt-0-m {
		padding-top: 0 !important;
	}
	.pt-4-m {
		padding-top: 3vmax !important;
	}
	.ml-0-m {
		margin-left: 0 !important;
	}
	.mx-0-m {
		margin-left: 0 !important;
		margin-right: 0 !important;
	}
	.d-none-m {
		display: none;
	}
	.mx-1 {
		margin-left: 0.5rem !important;
		margin-right: 0.5rem !important;
	}
	.above-the-fold {
		width: auto;
	}
	.heroshot {
		display: none;
	}
	.decor-above-fold {
		padding-top: 1vmax !important;
		width: auto;
		border-radius: 15px 0 220px 0;
		height: auto;
	}

	.dashboard .row .stats {
		padding: 0 2%;
	}

	.timer-separator {
		font-size: large;
		padding: 0 1vh;
	}
	.report-issues {
		top: -2vh;
		right: -1vh;
		padding: 0 0 1vh 1vh;
	}

	.report-issues i {
		font-size: inherit;
	}

	nav .logo {
		height: auto;
		max-width: 75%;
	}

	nav .brand-logo {
		position: absolute !important;
	}

	.under-nav,
	nav ul#dropdown1 {
		position: absolute;
		top: 64px !important;
	}

	.modal-report {
		height: 65% !important;
	}
	.modal-textarea {
		width: 95%;
		margin: 1vh auto;
	}
	/* .row {
		margin-bottom: 0 !important;
	} */
	h1 {
		font-size: 2.5rem;
	}

	form.flex-center {
		width: 100%;
		margin: 0;
	}

	.test-mode .container,
	.test-results {
		padding-top: 5vh;
		margin: 0;
		width: 100%;
	}

	.question {
		padding: 2.5vh 0.88rem;
		min-height: 10vmax;
	}

	.answers {
		/* display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center; */
		grid-template-columns: repeat(1, 1fr) !important;
		grid-auto-rows: minmax(85px, auto) !important;
	}

	.answer {
		min-height: 85px;
		min-width: 98%;
	}

	.next-question-button {
		/* height: 5vh; */
		margin: 0 2.5vh;
		text-align: right;
		/* position: absolute;
		bottom: 5vh;
		right: 5vh; */
	}

	.datepicker-day-button,
	.datepicker-table abbr {
		font-size: medium;
	}

	.history-card {
		height: 160px;
	}
}
/*#endregion*/
/* ----- MOBILE FRIENDLY ADJUSTMENTS ----- */
/*#region*/
@media only screen and (max-width: 767px) {
	.card .card-content p,
	div#question.card-panel ul,
	.question ul {
		font-size: 1.25rem;
		font-weight: 500;
	}
}
/*#endregion*/

/* SOCIAL LINKS */
/* #region */
.social-links {
	margin-top: 1.5rem;
	min-width: 80%;
	padding: 1.25rem 1rem;
	outline: 1px solid var(--brand2);
	text-align: center;
}

.social-links:hover {
	background-color: var(--brand2);
	color: var(--main-bg);
}
/* #endregion */
