@import 'color-variables.css';

/*=======================
       COLORS
=========================*/
/* BACKGROUNDS */
/*#region*/

.bg-brand,
.brand-bg {
	background-color: var(--brand) !important;
}
.brand-bg > a {
	color: var(--white) !important;
}
.bg-brand1 {
	background-color: var(--brand1) !important;
}
.bg-brand2 {
	background-color: var(--brand2) !important;
}

.brand-text {
	color: var(--brand);
}

.bg-dark {
	background-color: var(--black2) !important;
}
.bg-secondary {
	background-color: var(--gray) !important;
}
.bg-brand-1 {
	background-color: var(--brand-1) !important;
}
.bg-brand-2 {
	background: var(--brand-2);
}
.bg-brand-3 {
	background: var(--brand-3);
}
.bg-blue {
	/* background: #5d89ac; */
	background: var(--brand-3);
}
.bg-danger {
	background-color: var(--danger) !important;
}
.bg-danger-lighter {
	background-color: var(--danger-lighter) !important;
}
.bg-red {
	background-color: var(--red) !important;
}
.bg-yellow {
	background-color: var(--yellow) !important;
}
.bg-warning {
	background-color: var(--warning) !important;
}
/* ALTERNATE - START */
.bg-alternate1 {
	background: var(--alternate1) !important;
}
.bg-alternate1-1 {
	background: var(--alternate1-1) !important;
}
.bg-alternate1-white {
	background: var(--alternate1-white) !important;
}
.text-alternate1-white {
	color: var(--alternate1-white) !important;
}
.text-alternate1-off-white {
	color: var(--alternate1-off-white) !important;
}
/* ALTERNATE END */

/* ALTERNATE 1 - START */
.bg-alternate2 {
	background: var(--alternate2) !important;
}
.bg-alternate2-1 {
	background: var(--alternate2-1) !important;
}
.bg-alternate2-2 {
	background: var(--alternate2-2) !important;
}
.bg-alternate2-offwhite {
	background: var(--alternate2-off-white) !important;
}

.text-alternate2-white {
	color: var(--alternate2-white) !important;
}
.text-alternate2-off-white {
	color: var(--alternate2-off-white) !important;
}

/* ALTERNATE END */
/* ALTERNATE 3 - START */
.bg-alternate3 {
	background: var(--alternate3) !important;
	color: var(--alternate3-white) !important;
}

/* ALTERNATE END */

.bg-light-shade {
	background: var(--light-shade);
}
.bg-lighter-shade {
	background: var(--lighter-shade);
}
.bg-white {
	background: var(--white);
}
.bg-main {
	background: var(--main-bg) !important;
}
.bg-gray-1 {
	background: var(--gray-1) !important;
}

.bg-alert {
	background-color: var(--yellow-light) !important;
}
/*#endregion*/
