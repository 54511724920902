:root {
	/* https://stackoverflow.com/questions/55329996/how-to-create-color-shades-using-css-variables-similar-to-darken-of-sass*/
	/*You can build an entire brand based on 1 color */
	/*Swatches to cover: link, visited, hover, focus, active and other actionable elements */
	/* Darkest color and grays: to use as accents or backgrounds in light or dark modes*/

	/*
	Color Palette:
	- A brand color that works on light and dark backgrounds
	Whites & Blacks:
	- white (not necessarily pure white)
	- black (not necessarily pure black)
	Card or Form Input or subtitles or even body text Colors:
	- low contrast over light bg & high contrast over dark bg: (one color)
	- low contrast over dark bg & high contrast over light bg: (one color)
	Semantic or Denotive COlors:
	- Success:
	- Dager:
	- Warning:
	- Info:
	- Light-Disabled: light and dark shades of gray
	- Dark-Disabled: light and dark shades of gray
	Interactive Colors:
	- Pick 1 color (can be your brand)
	- 5 total shades of this color for:
	Swatches to cover: link, visited, hover, focus, active and other actionable elements
	Darkest color and grays: to use as accents or backgrounds in light or dark modes
		*/
	/*
		A shade is achieved by adding black to any pure hue,
		A tint is created by mixing white to any pure color.
		A tone is produced by adding gray to any pure hue.*/
	--brand-3: #6695f6;
	--brand-2: #5c92ff;
	--brand-1: #4780f5;
	--brand: #3b76ef; /* primary / base color 59,118,239*/
	--brand1: #2957b7;
	--brand2: #224ca4; /*94,65,197*/
	--brand-accent: #538cff;
	--brand-white: #f6fcff; /*#f2f9ff*/
	/* BLACKS & WHITES */
	--white: #ffffff; /*fafeff;*/
	--gray-1: #e3ebf1;
	--gray: #9eb7cf;
	--gray0: #8aa8c5;
	--gray1: #86a5c2;
	--gray2: #5d7d98;
	--gray3: #445e74;
	--black: #3b5470;
	--black-dark: #31465e;
	--black-darker: #233243;
	--black2: #283d5e;
	--dark-black: #081740;
	/*BACKGROUNDS */
	--lighter-shade: #eff7fd;
	--light-shade: #eef7fe;
	--off-white: #f1f9ff;
	/* --main-bg: #e4eff9; */
	--main-bg: #f4faff;
	--dark-shade: #d0def3;
	--darker-shade: #c5d8eb;
	/* SEMANTIC COLORS */
	--success: #03bc63;
	/* --success: #137d1c; */
	--success-light: #e0f7ec;
	--red: #f93943;
	/* --danger: #b72c0c; */
	--danger: #d82f29;
	--danger-light: #fcf1f0;
	--danger-lighter: #fef8f7;
	--warning: #f7af07;
	--yellow: #f9dc5c;
	--yellow-light: #fbe89d;
	--info: #2771f9;
	/* ALTERNATE 1 */
	--alternate1-dark: #8b52b9;
	--alternate1: #a66dd4;
	--alternate1-1: #b277e2;
	--alternate1-2: #c099e0;
	--alternate1-off-white: #e3d2f2;
	--alternate1-white: #f4f1fc;
	/* ALTERNATE 2 */
	--alternate2-dark: #008bd9;
	--alternate2: #63c7ff;
	--alternate2-1: #9bdcff;
	--alternate2-2: #a6dfff;
	--alternate2-off-white: #d9f1ff;
	--alternate2-white: #f7fbff;
	/* ALTERNATE 3 */
	--alternate3: #5d3fc5;
	--alternate3-1: #a294df;
	--alternate3-white: #f3f1fb;
	/* --test-mode-bg1: #183677; */
	--test-mode-bg1: #234da9;
	--test-mode-bg2: #183677;
	--test-mode-bg3: #152e66;

	/* ACCENTS */
	--light-accent: #66a3d2; /*Great for secondary buttons, cards, navbar, headlines, subtitles or icons or to bring attention to design elements by contrasting with the rest of the palette*/
	--dark-accent: #715591; /* Great for cards on light or dark bg, headlines or icons . Use sparingly to draw attention to important design elements*/
}
